module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 //NOSONAR ;
__p += '<li class="item item-container item-container-for-about-page">\n    <div class="attribute-container about-page-font-mobile">\n        <div class="attribute attribute-about-page" data-name="' +
((__t = ( i18n.aboutDescription )) == null ? '' : __t) +
'" data-mobile-name="' +
((__t = ( i18n.aboutDescription )) == null ? '' : __t) +
':">\n        <span>\n            ' +
((__t = ( i18n[`supportedProductDisplayName_${data.product}`] )) == null ? '' : __t) +
' ' +
((__t = ( data.release )) == null ? '' : __t) +
'\n            ';
 if (data.status === 2) { ;
__p += '\n                ';

                    const template = i18n.deprecatedTooltip;
                    const tooltip = DojoString.substitute(template, [data.sunsetOnDate]);
                ;
__p += '\n                <button type="button"\n                        class="warning-notification deprecated"\n                        style="display: inline-block;"\n                        title="' +
((__t = ( tooltip )) == null ? '' : __t) +
'"\n                        data-release="' +
((__t = ( data.release )) == null ? '' : __t) +
'"\n                        data-status="' +
((__t = ( data.status )) == null ? '' : __t) +
'"\n                        data-sunsetdate="' +
((__t = ( data.sunsetOnDate )) == null ? '' : __t) +
'" >\n                            <span class="icon-alert-warning"></span>\n                </button>\n            ';
 } ;
__p += '\n            ';
 if (data.status === 3) { ;
__p += '\n                ';

                    const template = i18n.sunsettedTooltip;
                    const tooltip = DojoString.substitute(template, [data.sunsetOnDate]);
                ;
__p += '\n                <button type="button"\n                        class="warning-notification sunsetted"\n                        style="display: inline-block;"\n                        title="' +
((__t = ( tooltip )) == null ? '' : __t) +
'"\n                        data-release="' +
((__t = ( data.release )) == null ? '' : __t) +
'"\n                        data-status="' +
((__t = ( data.status )) == null ? '' : __t) +
'"\n                        data-sunsetdate="' +
((__t = ( data.sunsetOnDate )) == null ? '' : __t) +
'" >\n                            <span class="icon-alert-warning"></span>\n                </button>\n            ';
 } ;
__p += '\n            </span>\n        </div>\n    </div>\n    <div class="attribute-container about-page-font-mobile">\n        <div class="attribute attribute-about-page" data-name="' +
((__t = ( i18n.aboutProviderName )) == null ? '' : __t) +
'" data-mobile-name="' +
((__t = ( i18n.aboutProviderName )) == null ? '' : __t) +
':">\n            <span>\n                ';

                    const providers = Object.keys(data.providers).sort();
                    const providerCount =providers.length;
                    let providerIndex = 0;
                ;
__p += '\n                ';
 for(provider of providers) { ;
__p += '\n                    ';
 providerIndex += 1; ;
__p += '\n                    ' +
((__t = ( i18n[`cloudProviderDisplayName_${provider}`] )) == null ? '' : __t) +
' (\n                        ';

                            const osCount = data.providers[provider].osList.length;
                            let osIndex = 0;
                        ;
__p += '\n                        ';
 for(os of data.providers[provider].osList) { ;
__p += '\n                            ';
 osIndex += 1; ;
__p += '\n                            ' +
((__t = ( i18n[`operatingSystem_${os}`] )) == null ? '' : __t) +
'\n                            ';
 if (osIndex < osCount) { ;
__p += '\n                                ,&nbsp;\n                            ';
 } ;
__p += '\n                        ';
 } ;
__p += '\n                    )\n                    ';
 if(providerIndex < providerCount) { ;
__p += '\n                        ,&nbsp;\n                    ';
 } ;
__p += '\n                ';
 } ;
__p += '\n            </span>\n        </div>\n    </div>\n</li>\n';

}
return __p
};
